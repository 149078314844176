import React from 'react';
import {useForm} from 'react-hook-form';
import {LOGIN_MUTATION} from './Api';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';

import navbarLogoDarkPng from '../images/logos/navbar_logo_dark.png';
import navbarLogoDarkWebp from '../images/logos/navbar_logo_dark.webp';

function LoginContainer({children}) {
  return (
    <div className="login-container">
      <div className="container" style={{paddingTop: '5%', maxWidth: '66%'}}>
        <div className="row">
          <div className="col-md-4 offset-md-1 pr-0">
            <div className="d-none d-md-block left-login-box shadow-8" />
          </div>
          <div className="col-md-7 pl-0">
            <div className="my-4 login-box rounded shadow-4 p-5">
              <div style={{textAlign: 'center'}}>
                <picture
                  style={{
                    height: 30,
                    display: 'contents',
                    maxHeight: '5rem',
                    maxWidth: '100%',
                  }}>
                  <source srcSet={navbarLogoDarkWebp} type="image/webp" />
                  <source srcSet={navbarLogoDarkPng} type="image/png" />
                  <img
                    src={navbarLogoDarkPng}
                    style={{maxHeight: '5rem', maxWidth: '100%'}}
                  />
                </picture>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function LoginScreen({setSession}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();
  const [login, {data}] = useMutation(LOGIN_MUTATION);
  let history = useHistory();

  const onSubmit = async (data) => {
    const result = await login({variables: data});
    const loginData = result.data.login;

    setSession(loginData);
    history.replace('/');
  };

  return (
    <LoginContainer>
      <form className="simple_form session" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label className="string" htmlFor="session_username">
            Benutzer
          </label>
          <input
            className="form-control string"
            type="text"
            {...register('username', {required: true})}
          />
        </div>
        <div className="form-group">
          <label className="password" htmlFor="session_password">
            Passwort
          </label>
          <input
            className="form-control password"
            type="password"
            {...register('password', {required: true})}
          />
        </div>
        <input type="submit" name="commit" className="btn btn-primary" />
      </form>
    </LoginContainer>
  );
}
