import {Store} from 'pullstate';

export const NetworkStore = new Store({
  loading: false,
  error: null,
});

export const HousesStore = new Store({
  houses: [],
  selectedHouse: null,
  hoveredHouse: null,
  listOwnHouses: true,
});
