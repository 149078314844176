import React from 'react';
import {
  Map,
  TileLayer,
  Marker,
  Tooltip,
  LayersControl,
  ScaleControl,
} from 'react-leaflet';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import 'daemonite-material/js/material.min.js';
import Modal from 'react-bootstrap/Modal';
import {HousesStore} from './stores';

const HouseImagesCarousel = ({images}) => {
  images = images.map((image, index) => (
    <div key={image} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
      <img className="d-block w-100" alt="Autohof Bild" src={image} />
    </div>
  ));

  return (
    <div id="carousel" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">{images}</div>
      <a
        className="carousel-control-prev"
        href="#carousel"
        role="button"
        data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel"
        role="button"
        data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

const HouseModal = () => {
  const house = HousesStore.useState((s) => s.selectedHouse);

  let free = house?.free_spots;
  if (free < 0) {
    free = 0;
  }

  return (
    <Modal
      size="lg"
      show={house !== null}
      onHide={() =>
        HousesStore.update((s) => {
          s.selectedHouse = null;
        })
      }>
      <Modal.Header closeButton>
        <Modal.Title>{house?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {house?.images?.length > 0 && (
          <HouseImagesCarousel images={house.images} />
        )}

        <table className="table">
          <tbody>
            <tr>
              <td>Freie Parkplaetze</td>
              <td>{free}</td>
            </tr>
            <tr>
              <td>Distanz</td>
              <td>
                {house?.distance === null
                  ? 'unbekannt'
                  : house?.distance?.toFixed() + ' km'}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={() =>
            HousesStore.update((s) => {
              s.selectedHouse = null;
            })
          }
          type="button"
          className="btn btn-secondary"
          data-
          dismiss="modal">
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const greenIcon = new L.Icon({
  iconUrl: require('../images/parking_point_green.svg'),
  iconRetinaUrl: require('../images/parking_point_green.svg'),
  iconSize: new L.Point(40, 60),
});

const orangeIcon = new L.Icon({
  iconUrl: require('../images/parking_point_orange.svg'),
  iconRetinaUrl: require('../images/parking_point_orange.svg'),
  iconSize: new L.Point(40, 60),
});

const greyIcon = new L.Icon({
  iconUrl: require('../images/parking_point_grey.svg'),
  iconRetinaUrl: require('../images/parking_point_grey.svg'),
  iconSize: new L.Point(40, 60),
});

const redIcon = new L.Icon({
  iconUrl: require('../images/parking_point_red.svg'),
  iconRetinaUrl: require('../images/parking_point_red.svg'),
  iconSize: new L.Point(40, 60),
});

const MapComponent = () => {
  const map = React.useRef();

  const {houses, hoveredHouse, listOwnHouses} = HousesStore.useState((s) => ({
    houses: s.houses,
    hoveredHouse: s.hoveredHouse,
    listOwnHouses: s.listOwnHouses,
  }));

  const markers = houses.map((house) => {
    let houseIcon = greenIcon;
    if (house.free_spots <= 0) {
      houseIcon = redIcon;
    } else if (house.free_spots < 10) {
      houseIcon = orangeIcon;
    } else if (hoveredHouse !== null && hoveredHouse.id === house.id) {
      houseIcon = greyIcon;
    }

    return (
      <Marker
        icon={houseIcon}
        onClick={() =>
          HousesStore.update((s) => {
            s.selectedHouse = house;
          })
        }
        key={house.id}
        position={[house.lat, house.lon]}>
        <Tooltip>{house.name}</Tooltip>
      </Marker>
    );
  });

  const location = [51.133333333333, 10.41666];

  const onOwnHousesFilter = React.useCallback((event) => {
    HousesStore.update((s) => {
      s.listOwnHouses = event.target.checked;
    });
  }, []);

  return (
    <div className="map-container">
      <Map ref={map} center={location} zoom={7}>
        <ScaleControl />
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <HouseModal />
        {markers}

        <Control position="topleft">
          <div className="form-group form-check">
            <input
              checked={listOwnHouses}
              type="checkbox"
              className="form-check-input"
              onChange={onOwnHousesFilter}
            />
            <label className="form-check-label">Nur eigene Betriebe</label>
          </div>
        </Control>

        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik" checked={true}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenStreetMap.DE">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="OpenStreetMap.CH">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.osm.ch/switzerland/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Stadia.AlidadeSmooth">
            <TileLayer
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Stadia.AlidadeSmoothDark">
            <TileLayer
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri.WorldImagery">
            <TileLayer
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </Map>
    </div>
  );
};

export default MapComponent;
