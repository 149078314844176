import React from 'react';
import {client, LIST_HOUSES_QUERY, LOGIN_MUTATION} from './Api';
import Map from './Map';
import {HousesStore} from './stores';
import {LineChart, PieChart} from 'react-chartkick';

import navbarLogoWebp from '../images/logos/navbar_logo.webp';
import navbarLogoPng from '../images/logos/navbar_logo.png';
import chart2Webp from '../images/chart2.webp';
import chart2Jpg from '../images/chart2.jpg';

import LoginScreen from './Login';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';

const SessionContext = React.createContext('session');

function BaseLayout({children}) {
  return (
    <React.Fragment>
      <Header />
      <SidebarContainer />
      <div className="container mt-xl-5" style={{marginTop: '56'}}>
        <div className="row">
          <div className="col-md-12">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Header() {
  return (
    <React.Fragment>
      <header className="d-print-none d-xl-block d-none fixed-top navbar bg-dark navbar-dark navbar-full bg-primary doc-navbar-default">
        <button
          aria-controls="navdrawerDefault"
          aria-expanded="false"
          aria-label="Toggle Navdrawer"
          className="d-xl-none navbar-toggler"
          data-target="#navdrawerDefault"
          data-toggle="navdrawer">
          <span className="navbar-toggler-icon" />
        </button>
        <a className="navbar-brand mr-auto" href="/">
          <picture
            style={{
              height: 30,
              display: 'contents',
              maxHeight: '100%',
              maxWidth: '100%',
            }}>
            <source srcSet={navbarLogoWebp} type="image/webp" />
            <source srcSet={navbarLogoPng} type="image/png" />
            <img
              src={navbarLogoPng}
              style={{maxHeight: '100%', maxWidth: '100%'}}
            />
          </picture>
        </a>
      </header>
      <header className="d-print-none d-xl-none navbar bg-dark navbar-dark navbar-full bg-primary doc-navbar-default">
        <button
          aria-controls="navdrawerDefault"
          aria-expanded="false"
          aria-label="Toggle Navdrawer"
          className="d-xl-none navbar-toggler"
          data-target="#navdrawerDefault"
          data-toggle="navdrawer">
          <span className="navbar-toggler-icon" />
        </button>
        <a className="navbar-brand mr-auto" href="/">
          <picture
            style={{
              height: 30,
              display: 'contents',
              maxHeight: '100%',
              maxWidth: '100%',
            }}>
            <source srcSet={navbarLogoWebp} type="image/webp" />
            <source srcSet={navbarLogoPng} type="image/png" />
            <img
              src={navbarLogoPng}
              style={{maxHeight: '100%', maxWidth: '100%'}}
            />
          </picture>
        </a>
      </header>
    </React.Fragment>
  );
}

function Sidebar() {
  const session = React.useContext(SessionContext);

  return (
    <div className="navdrawer-content">
      <div className="navdrawer-header bg-dark d-xl-none d-print-none" />
    </div>
  );
}

function SidebarContainer() {
  return (
    <React.Fragment>
      <div
        id="navdrawerPermanentClipped"
        className="d-print-none d-none d-xl-block navdrawer navdrawer-permanent navdrawer-permanent-clipped">
        <Sidebar />
      </div>
      <div id="navdrawerDefault" className="d-print-none navdrawer d-xl-none">
        <Sidebar />
      </div>
    </React.Fragment>
  );
}

function HouseList() {
  const {loading, error, data} = useQuery(LIST_HOUSES_QUERY, {
    variables: {ownHouses: true},
    pollInterval: 15000,
  });

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error :</p>;
  }

  const diagrams = data.houses.map((house) => {
    return (
      <div className="col-md-6" key={house.id}>
        <h4>{house.name}</h4>
        <PieChart
          data={[
            ['Frei', house.freeSpots],
            ['Benutzt', house.bookableSpots - house.freeSpots],
          ]}
          colors={['#43A047', '#e53935']}
        />
      </div>
    );
  });

  return (
    <BaseLayout>
      <div className="card">
        <picture className="card-img-top" style={{display: 'contents'}}>
          <source srcSet={chart2Webp} type="image/webp" />
          <source srcSet={chart2Jpg} type="image/jpg" />
          <img className="card-img-top" src={chart2Jpg} style={{}} />
        </picture>

        <div className="card-body">
          <h4 className="card-title">Auslastung</h4>
          <div className="card-text">
            <div className="row">{diagrams}</div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

function Routing() {
  // dont inline this into App as useHistory will not work then
  let history = useHistory();
  const [session, setSession] = React.useState(null);

  const persistSession = React.useCallback((session) => {
    setSession(session);
    localStorage.setItem('session', JSON.stringify(session));
  }, []);

  React.useEffect(() => {
    const session = localStorage.getItem('session');
    if (session === null) {
      history.replace('/login');
    } else {
    }
  }, []);

  return (
    <SessionContext.Provider value={session}>
      <Switch>
        <Route path="/login">
          <LoginScreen setSession={persistSession} />
        </Route>
        <Route path="/users">
          <HouseList />
        </Route>
        <Route path="/">
          <HouseList />
        </Route>
      </Switch>
    </SessionContext.Provider>
  );
}

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routing />
      </Router>
    </ApolloProvider>
  );
}

export default App;
